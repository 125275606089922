@use "variables" as variables;
@use "mixin" as mixin;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&family=Montserrat:wght@400;500;700;800;900&family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css");

body {
  &.noScroll {
    overflow: hidden;
    height: 100vh;
    left: 0;
    position: fixed;
    width: 100%;

    &:before {
      content: '';
      background: rgba( 0, 0, 0, 0.4 );
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 500;
    }
  }
}

/* link
------------------------------------------------------------*/
button,
a {
  color: variables.$COLOR_TEXT;
  display: inline-block;
  vertical-align: baseline;
  text-decoration: underline;
  transition: 0.5s ease;

  /* ▼ 下線跡切れ対策 ▼ */
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;

  &:visited,
  &:active {
    text-decoration: none;
    outline: none !important;
    will-change: transform;
  }

  &:hover {
    color: variables.$COLOR_MAIN;
    opacity: .75;
    text-decoration: none;
    transition: .5s ease;
  }

  [href$=".png"],
  [href$=".jpg"],
  [href$=".jpeg"],
  [href$=".jpe"],
  [href$=".gif"],
  [href$=".bmp"],
  [href$=".ico"] {
    border: none;
  }
}

button {
  margin: 0;
  padding: 0;
  &:hover {
      opacity: .7;
  }
}

img {
  width: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  flex-shrink: 0;
  -ms-interpolation-mode: bicubic;
}

area {
  cursor: pointer;
}

sup {
  font-size: 65%;
  top: -0.4em;
}

/* Fonts
------------------------------------------------------------*/
html {
  transition: font-size .5s;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  background-color: white;
  color: variables.$COLOR_TEXT;
  font-family: variables.$FONT_BASE;
  font-size: map_get(variables.$BASE, font-size);
  @include mixin.propertyClamp("font-size", 14, 16, 769, 960);//ブラウザ幅 769px-14px,960px-16px
  font-weight: 500;
  line-height: map_get(variables.$BASE, line-height);
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* text-align: justify;
  text-justify: inter-ideograph; */
  line-break: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;

  @include mixin.tabletP {
    @include mixin.propertyClamp("font-size", 14, 16, 320, 390);//ブラウザ幅 320px-14px,390px-16px
  }

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: map_get(variables.$COLOR, gray);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
      background: variables.$COLOR_TEXT_GRAY;
      border-radius: 5px;
  }

  /* &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #000;
    outline-offset: 2px;
  } */

  //マウスクリック時はfocus非表示
  &:focus:not(:fous-visible) {
    outline: none !important;
    transition: none !important;
  }
  //タブ移動時はfocus表示
  &:focus-visible {
    outline: 2PX solid rgba(0, 0, 0, 1) !important;
    outline: -webkit-focus-ring-color auto 1px;
    transition: none !important;
    outline-offset: -1px !important;

    @include mixin.tabletP {
      outline: 0px solid rgba(0, 0, 0, 0) !important;
    }
  }
}

input,
button,
textarea,
select {
  font-family: variables.$FONT_BASE;
  line-height: map_get(variables.$BASE, line-height);
}

/* ==[ Base ]==================================== */
* {
  box-sizing: border-box;
  min-height: 0;
  min-width: 0;
  zoom: 1;

  &:before,
  &:after {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
    zoom: 1;
  }
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
  transition: all 0.3s ease;
  /* overflow-x: hidden; */
}

main {
  display: block;
  margin: 0 auto;
  padding: 0;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

article,
section {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    pointer-events: none;
  }
}

.sec-inner {
  padding: 50px 0;
  margin: 0;

  @include mixin.tabletP {
    padding: 40px 0;
  }
}

footer {
  margin-top: auto;
  width: 100%;
}

ol,
ul {
  list-style: none;
  margin: 0;
  outline: none !important;
}

p {
  margin-bottom: 0;
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

/* ==[ Bootstrap CSS ]==================================== */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 0;
  padding-left: 0;
}

.container-full {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.c-container-xs {
  margin-right: auto;
  margin-left: auto;
  max-width: 820px;
  padding-right: 0;
  padding-left: 0;
}

.c-container-sm {
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  padding-right: 0;
  padding-left: 0;
}

.c-container-md {
  margin-right: auto;
  margin-left: auto;
  max-width: 1024px;
  padding-right: 0;
  padding-left: 0;
}

.c-container-lg {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-right: 0;
  padding-left: 0;
}

.c-container-xl {
  margin-right: auto;
  margin-left: auto;
  max-width: 1280px;
  padding-right: 0;
  padding-left: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

[class^="col"]:not(.collapse):not(.collapse-inner):not(.col-img) {
  @include mixin.multiple-p('left', '2');
  @include mixin.multiple-p('right', '2');
  /* @include mixin.multiple-p(null, '2'); */
  @include mixin.sp {
    padding-right: 0;
    padding-left: 0;
  }
}

.card {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.card-img {
  border-radius: 0;

  img {
    width: auto;
    max-width: 100%;
  }

  &.ratio {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
    }

    >img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.card-header {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
}

.card-body {
  padding: 0;
}

.card-footer {
  background-color: transparent;
  border-top: 0;
  padding: 0;
}


.card-title {
  margin-bottom: 0;
}

.wovn-switch {
  cursor: pointer;

  &.selected {
    display: none!important;
  }
}
