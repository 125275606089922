@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

@mixin transiton() {
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      @if($i == 1) {
        transition-delay: 1s;
      } @else {
        transition-delay: #{($i - 1) * .5}s;
      }
    }
  }
}

[aria-hidden="false"],
button[aria-hidden="false"] {
  display: block !important;
  transition: .5s;
}

[aria-hidden="true"],
button[aria-hidden="true"] {
  display: none !important;
  transition: .5s;
}

[data-aria="pc"] {
  display: block;

  @include mixin.tabletP {
    display: none;
  }
}

[data-aria="sp"] {
  display: none;

  @include mixin.tabletP {
    display: block;
  }
}

.gradation1 {
  background: rgb(255,177,128);
  background: linear-gradient(110deg, rgba(255,177,128,1) 0%, rgba(255,133,102,1) 100%);
}

.gradation2 {
  background: rgb(255,203,128);
  background: linear-gradient(110deg, rgba(255,203,128,1) 0%, rgba(255,133,102,1) 100%);
}

.p-box-shadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .08);
}

.boxshadow-none {
  box-shadow: none !important;
}

.p-ttl-flex {
  display: flex;
  align-items: center;
  gap: 0 24px;
  @include mixin.multiple-m('bottom', '2');

  @include mixin.tabletP {
    gap: 0 20px;
  }

  .p-ttl {
    background-color: map_get(variables.$COLOR, middle-gray);
    color: variables.$COLOR_MAIN;
    display: inline-block;
    font-size: calc(14px + 2 * (100vw - 375px) / 1545);
    font-weight: 700;
    padding: 8px 12px;
    text-align: center;
    width: 104px;
    line-height: 1;
    white-space: nowrap;

    @include mixin.tabletP {
      padding: 6px;
      width: 100px;
    }
  }
}

.c-ratio {
  position: relative;

  &::before {
    content: "";
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.c-card-wrap {
  .c-card {
    .c-card-header {
      @include mixin.multiple-m('bottom', '3');

      @include mixin.tabletP {
        @include mixin.multiple-m('bottom', '4');
      }
      @include mixin.sp {
        @include mixin.multiple-m('bottom', '2.5');
      }
    }

    .c-card-body {
      @include mixin.multiple-m('bottom', '3');

      @include mixin.sp {
        @include mixin.multiple-m('bottom', '2');
      }
    }

    .c-card-footer {
      text-align: right;
    }
  }
}

.p-heading-flex {
  box-sizing: border-box;
  align-items: flex-end;
  display: flex;
  white-space: nowrap;
  opacity: .5;

  li {
    color:rgba(0,0,0,.0);
    font-family: 'Lato', sans-serif;
    font-size: calc(56px + 94 * (100vw - 375px) / 1545);
    font-weight: 900;
    line-height: 1;
    /* stroke: #231815;
    stroke-width: 1;
    stroke-linejoin: round; */
    -webkit-text-stroke: 1px #c2c2c2;
  }

  span {
    display: inline-block;
    padding: 0 30px;
  }
}

.p-heading-unit {
  overflow: hidden;
  z-index: 2;

  &.heading,
  &.heading-type1,
  &.heading-type2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
  }

  &.heading-anime1 {
    animation: slideInRight 1s forwards ease;
  }

  &.heading-type2 {
    .p-heading-flex {
      li {
        opacity: 0;
        will-change: transform;
        @include transiton();

        &.heading-anime2 {
          animation: slideInLeft forwards ease;

          &:nth-child(1) {
            animation-delay: .1s;
          }
          &:nth-child(2) {
            animation-delay: .2s;
          }
          &:nth-child(3) {
            animation-delay: .3s;
          }
          &:nth-child(4) {
            animation-delay: .4s;
          }
          &:nth-child(5) {
            animation-delay: .5s;
          }
          &:nth-child(6) {
            animation-delay: .6s;
          }
          &:nth-child(7) {
            animation-delay: .7s;
          }
          &:nth-child(8) {
            animation-delay: .8s;
          }
          &:nth-child(9) {
            animation-delay: .9s;
          }
          &:nth-child(10) {
            animation-delay: 1s;
          }
          &:nth-child(11) {
            animation-delay: 1.1s;
          }
          &:nth-child(12) {
            animation-delay: 1.2s;
          }
          &:nth-child(13) {
            animation-delay: 1.3s;
          }
          &:nth-child(14) {
            animation-delay: 1.4s;
          }
        }
      }
    }
  }
}

.overlay {
  background-color: rgba(34, 34, 34, .6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  transition: .5s;
  will-change: transform;

  &[aria-hidden="false"] {
    transition: .8s;
  }

  &[aria-hidden="true"] {
    transition: .8s;
  }
}

.width-md,
.width-sm {

  .c-card-wrap {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &::before {
      background: white;
      content: "";
      display: inline-block;
      width: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    .c-card {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }

    &.move {
      &::before {
        animation: passingBar .5s ease 0s normal forwards;
      }

      .c-card {
        animation: passingTxt .4s ease .1s normal forwards;
      }
    }
  }
}

/* アニメーション */
.leftAnime {
  opacity: 0;
}

.fadeupAnime {
  opacity: 0;
}

.slideAnimefadeup {
  animation: fadeupAnime .4s forwards;
  opacity: 0;
}

.slideAnimeLeftRight {
	animation: slideTextX100 2s forwards;
  opacity: 0;
}

.slideAnimeRightLeft {
	animation: slideTextX-100 1.5s forwards;
  opacity: 0;
}

@keyframes fadeupAnime {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideTextX100 {
  from {
    transform: translateX(-120%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideTextX-100 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* アニメkeyframes */
@keyframes passingBar {
  0% {
    left: 0;
    width: 100%;
  }

  60% {
    left: 0;
    width: 100.1%
  }

  100% {
    left: 200%;
    width: 0;
  }
}

@keyframes passingTxt {
	0% {
    opacity:0;
  }
	70% {
    opacity:0;
  }
	100% {
    opacity:1;
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(140vw,0,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  /* 0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  } */
}

@keyframes loopUp {
  from {
    background-position: 0 0;
    transform: translate3d(0, 0, 0);
  }
  to {
    background-position: 0 -70%;
    transform: translate3d(0, -70%, 0);

  }
}

@keyframes loopDown {
  from {
    background-position: 0 -70%;
    transform: translate3d(0, -70%, 0);

  }
  to {
    background-position: 0 0;
    transform: translate3d(0, 0, 0);
  }
}


.acod-area {
  @include mixin.multiple-m(bottom, '5');

  .acod-container {
    margin: 0 auto;
  }

  .acod-header {
    border-bottom: 1px map_get(variables.$COLOR, gray) solid;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    padding: 24px 0;
    padding-right: 32px;
    position: relative;
    transition: .5s;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
    }

    &::before {
      background-color: variables.$COLOR_MAIN;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      transition: .3s;
      transform: scale(0, 1);
      transform-origin: left top;
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_toggle_plus.svg')}) no-repeat center center;
      background-size: 100% auto;
      width: 22px;
      height: 22px;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(0deg);
      transition: .3s ease;
      transform-origin: center center;
    }

    &:hover {
      &::before {
        transform: scale(1, 1);
      }
    }

    &.open {
      border-bottom-color: variables.$COLOR_MAIN;
      transition: .5s;

      &::before {
        display: none;
      }

      &::after {
        background: url(#{mixin.set_common_path('icon_toggle_minus.svg')}) no-repeat center center;
        background-size: 100% auto;
        transform: translateY(-50%) rotate(-180deg);
        transition: .3s ease;
      }
    }
  }

  .acod-inner {
    display: none;
    padding: 40px 0;
  }

  .acod-body {
    .c-search-area {
      gap: 16px;
    }

    li {
      position: relative;

      .btn-modal {
        display: block;
        padding-right: 0;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.modal-open {
  padding-right: 0 !important;

  /* @include mixin.tabletP {
    padding: 0 32px !important;
  }
 */
  .modal-backdrop {
    z-index: 8000!important;
  }
}

.modal {
  padding-right: 0 !important;
  z-index: 8050!important;

  @include mixin.tabletL {
    padding: 32px !important;
  }

  .modal-custom {
    margin: 0 auto;
    max-width: 960px;
    z-index: 8050!important;

    @include mixin.tabletL {
      max-width: 100%;
    }

    .modal-content {
      border: none;
      border-radius: 0;
      padding: 64px 80px;
      position: relative;

      @include mixin.tabletP {
        padding: 72px 24px;
        @include mixin.multiple-p(right, '2');
      }

      .inn-container-sm {

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-track {
          background: map_get(variables.$COLOR, gray);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: variables.$COLOR_TEXT_GRAY;
            border-radius: 5px;
        }
      }
    }

    .modal-header {
      border-bottom: none;
      padding: 0;
      @include mixin.multiple-p(bottom, '5');

      @include mixin.tabletP {
        @include mixin.multiple-p(bottom, '3');
      }
    }

    .modal-title {
      font-size: calc(22px + 2 * (100vw - 375px) / 1545);
      font-weight: 700;
      @include mixin.multiple-m(bottom, '0');
    }

    .modal-body {
      padding: 0;
      @include mixin.multiple-p(right, '3');

      &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: map_get(variables.$COLOR, gray);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
          background: variables.$COLOR_TEXT_GRAY;
          border-radius: 5px;
      }
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.c-ttl-img {
  position: absolute;
  top: -48px;
  z-index: 3;

  @include mixin.tabletP {
    top: -6px;
    padding: 0 16px;
  }

  .contents-ttl {
    -webkit-text-stroke: 1px variables.$COLOR_MAIN;
    color: transparent;
    font-family: variables.$FONT_ALPHA;
    font-size: 100px;
    /* font-size: calc(48px + 52 * (100vw - 375px) / 1545); */
    font-weight: 900;
    line-height: 1.6;
    opacity: .5;

    @include mixin.tabletP {
      font-size: 48px;
    }
  }
}

.yt-unit {
  padding-top: 56.25%;
  @include mixin.multiple-m(bottom, '5');
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}



/* 20241114追加 */
.myBlockAccordion {

  &__toggle {
    border-bottom: 1px map_get(variables.$COLOR, gray) solid;
    display: block;
    font-weight: 700;
    padding: 24px 0;
    padding-right: 32px;
    text-align: left;
    text-decoration: none;
    width: 100%;
    position: relative;
    transition: .3s ease-in-out;

    &::before,
    &::after {
      background-color: variables.$COLOR_MAIN;
      content: "";
      display: block;
      height: 2px;
      width: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: .3s ease-in-out;
    }

    &::before {
      right: 0;
    }

    &::after {
      right: 0;
      transform: translateY(-50%) rotate(90deg);
    }

    &[aria-expanded="true"] {
      border-bottom-color: variables.$COLOR_MAIN;

      &::after {
        transform: translateY(-50%) rotate(0deg);
      }
    }

    &:hover {
      color: variables.$COLOR_TEXT;
      opacity: 1;
    }
  }

  &__body {
    background-color: #F9F9F9;
    width: 100%;

    &-inner {
      padding: 40px;
      position: relative;

      @include mixin.tabletP {
        padding: 24px 16px;
      }
    }

    .g-slide {
      margin: 0 auto;
    }
  }
}
