@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/* 下層テンプレート */
.modContentsContainer {
  padding-bottom: 50px;
}

.modContents {
  .alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1.5em;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }

  .aligncenter {
    margin-bottom: 20px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before,
  h6:before,
  h1:after,
  h2:after,
  h3:after,
  h4:after,
  h5:after,
  h6:after {
    content: "";
    display: table;
    table-layout: fixed;
    clear: both;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: none;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;
  }

  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }

  h2 {
    border-top: 1px #c6c6c6 solid;
    border-bottom: 1px #c6c6c6 solid;
    font-family: 'Shippori Mincho', serif;
    font-weight: 700;
    padding: 15px 0;
    margin-bottom: 40px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background-color: #0085cd;
      position: absolute;
      top: -1px;
      left: 0;
    }
  }

  h3 {
    background-color: #cce6f5;
    font-weight: 700;
    padding: 5px 15px;
  }

  h4 {
    border-left: 5px #909090 solid;
    font-weight: 700;
    padding-left: 15px;
    line-height: normal;
    margin-bottom: 30px;
  }

  h5 {
    margin-bottom: 20px;
    font-weight: 700;
  }

  h6 {
    border-bottom: 1px #333 solid;
    font-size: calc(16px + 4 * (100vw - 375px) / 1545);
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 50px;
  }

  ul:not(.list_1):not(.list_1 li ul):not(.wp-pagenavi):not(.modListJump) {
    margin-bottom: 50px;
    list-style: none;

    li {
      font-weight: 700;
      padding-left: 30px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      @include mixin.tabletP {
        padding-left: 30px;
      }

      &::before {
        background: #909090;
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: ol_li;
    padding-left: 0;
    margin-bottom: 50px;

    li {
      font-weight: 700;
      padding-left: 30px;
      margin-bottom: 10px;
      word-wrap: break-word;
      position: relative;

      &::before {
        counter-increment: ol_li;
        content: "" counter(ol_li);
        font-weight: 700;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  }

  dl.news-list {
    border-bottom: 4px #191F1D dotted;
    padding: 20px 0;

    dt {
      &.date {
        font-size: 16px;
        font-weight: normal;
      }
    }

    .post-cont {
      a {
        color: #1A1311;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;

        &:hover {
          color: #447067;
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
  }

  .wp-caption,
  img {
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  .wp-caption {
    p {
      margin: 0;
      word-break: break-all;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-break: anywhere;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea {}

  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {}

  iframe {
    width: 100%;
    max-width: 100%;
  }

  a {
      color: #0085cd;

      &:hover {
          text-decoration: underline;
          transition: .5s ease;
      }

      &:visited {
          color: #6367a7;
      }
  }
  .btn_pdf,
  .btn_doc,
  .btn_xls,
  .btn_ppt {
    display: inline-block;
    padding-right: 35px;
    margin-right: 10px;
  }

  .btn_pdf {
    background: url(#{mixin.set_common_path('bg_pdf.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_doc {
    background: url(#{mixin.set_common_path('bg_doc.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_xls {
    background: url(#{mixin.set_common_path('bg_xls.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_ppt {
    background: url(#{mixin.set_common_path('bg_ppt.svg')}) no-repeat right center;
    background-size: auto;
  }

  .btn_link {
    background: url(#{mixin.set_common_path('bg_blank.svg')}) no-repeat right center;
    background-size: auto;
    display: inline-block;
    padding-right: 25px;
  }

  .btn01 {
    background-color: #0085cc;
    border: 2px #0085cc solid;
    color: white;
    display: inline-block;
    text-decoration: none !important;
    padding: 20px 30px;
    padding-right: 80px;
    line-height: normal;
    max-width: 100%;
    position: relative;

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_off.svg')}) no-repeat center center;
      background-size: 100%;
      content: "";
      display: block;
      width: 32px;
      height: 8px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    &:visited {
      color: white;
    }

    &:hover {
      background-color: white;
      color: #0085cc;

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_on.svg')}) no-repeat center center;
        background-size: 100%;
      }
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;

    th,
    td {
      padding: 10px 30px;

      @include mixin.tabletP {
        padding: 10px 15px;
      }
    }

    thead {

      th,
      td {
        border: 1px #333 solid;
        font-weight: 700;
      }

      th {
        width: 30%;
      }

      td {
        width: 70%;
      }
    }

    tbody {

      tr {
        &:first-child {
          th,td {
            background-color: #dedede;
            border: 1px #f2f2f2 solid;
            font-weight: 700;
          }
        }
      }

      th,td {
        border-bottom: 1px #dedede solid;
      }

      th {
        background-color: #f2f2f2;
        font-weight: 500;
        vertical-align: middle;
        width: 30%;
      }

      td {
        background: white;
        background-color: white;
        width: 70%;
      }
    }

    .wp-caption {
      p {
        margin: 0;
      }

      img {
        margin: 0 auto;
        padding: 0;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &.tbl_none {
      border: none;
      display: table;
      table-layout: fixed;

      tr {
        &:first-child {
          th,td {
            background-color: transparent;
            border: none;
          }
        }
      }

      th,
      td {
        background: none;
        border: none;
        vertical-align: top;
        text-align: left;
        margin: 0;
        padding: 10px;

        p {
          font-weight: normal;
          margin-bottom: 20px;
        }

        h3 {
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    &.tbl_fix {
      table-layout: fixed;
    }

    &.tbl_responsive {
      border: none;
      table-layout: fixed;

      tr {
        &:first-child {
          th,td {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }

  table.toggle_panel {
    border-collapse: collapse;
    border: none;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;

    &.last-tbl {
      margin-bottom: 80px;
    }

    > {
      tbody>tr> {

        th,
        td {
          background: none;
          border: none;
          vertical-align: top;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }

      tr {
        > {

          th,
          td {
            background: none;
            border: none;
            vertical-align: top;
            text-align: left;
            margin: 0;
            padding: 0;
          }
        }

        &:nth-child(1)>td {
          position: relative;
          cursor: pointer;
        }
      }

      tbody>tr:nth-child(1)>td {
        position: relative;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }
    }

    tr {
      &:nth-child(1) {

        >td {
          h3 {
            background-color: transparent;
            border-left: none;
            border-bottom: 2px #ebebeb solid;
            font-size: calc(18px + 2 * (100vw - 320px) / 1600);
            font-weight: 700;
            margin: 0;
            padding: 20px 0;
            padding-right: 50px;
            margin-right: -1px;
            line-height: normal;

            &.toggle_open {

              &::after {
                background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
              }
            }

            &:after {
              background: url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
              height: 100%;
              position: absolute;
              width: 15px;
              height: 15px;
              top: 25px;
              right: 20px;
            }
          }
        }
      }
    }

    > {

      tr:nth-child(2)>td,
      tbody>tr:nth-child(2)>td {
        padding: 20px;
        display: none;
        position: relative;
      }
    }
  }

  table.toggle_panel02 {
    border-collapse: collapse;
    border: none;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;

    &.last-tbl {
      margin-bottom: 80px;
    }

    > {
      tbody>tr> {

        th,
        td {
          background: none;
          border: none;
          vertical-align: top;
          text-align: left;
          margin: 0;
          padding: 0;
        }
      }

      tr {
        > {

          th,
          td {
            background: none;
            border: none;
            vertical-align: top;
            text-align: left;
            margin: 0;
            padding: 0;
          }
        }

        &:nth-child(1)>td {
          position: relative;
          cursor: pointer;
        }
      }

      tbody>tr:nth-child(1)>td {
        position: relative;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }
    }

    tr {
      &:nth-child(1) {

        >td {
          h3 {
            background-color: transparent;
            border-left: none;
            border-bottom: 2px #ebebeb solid;
            font-size: calc(20px + 2 * (100vw - 320px) / 1600);
            font-weight: 700;
            margin: 0;
            padding: 20px 50px;
            padding-right: 70px;
            margin-right: -1px;
            line-height: normal;

            &.toggle_open {

              &::after {
                background: url(#{mixin.set_common_path('toggle_minus.svg')}) no-repeat center center;
              }
            }

            &::before {
              color: #0185cc;
              content: "Q";
              display: inline-block;
              font-family: 'Shippori Mincho', serif;
              font-size: calc(20px + 2 * (100vw - 375px) / 1545);
              font-weight: 700;
              position: absolute;
              top: 20px;
              left: 20px;
            }

            &:after {
              background:  url(#{mixin.set_common_path('toggle_plus.svg')}) no-repeat center center;
              content: "";
              height: 100%;
              position: absolute;
              width: 15px;
              height: 15px;
              top: 25px;
              right: 20px;
            }
          }
        }
      }
    }

    > {

      tr:nth-child(2)>td,
      tbody>tr:nth-child(2)>td {
        padding: 30px 15px;
        padding-left: 50px;
        padding-right: 70px;
        display: none;
        position: relative;

        &::before {
          color: #909090;
          content: "A";
          display: inline-block;
          font-family: 'Shippori Mincho', serif;
          font-size: calc(18px + 2 * (100vw - 375px) / 1545);
          font-weight: 700;
          position: absolute;
          top: 28px;
          left: 20px;
        }
      }
    }
  }

  .flow-unit01,
  .flow-unit02 {

    li {
      background-color: #e5f4fb;
      padding: 30px;
      margin-bottom: 60px;
      position: relative;

      &::after {
        position: absolute;
        bottom: -43px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background:  url(#{mixin.set_common_path('flow_icon.svg')}) no-repeat center center;
      }

      &:last-of-type::after {
        display: none;
      }

      >p {
        &:first-of-type {
          font-family: 'Shippori Mincho', serif;
          font-size: calc(18px + 4 * (100vw - 320px) / 1600);
          margin-bottom: 10px;
          line-height: normal;
        }
      }

      div {
        p {
          &:last-of-type {
            font-weight: normal;
            margin-bottom: 0;
            line-height: normal;
          }
        }
      }
    }
  }

  .flow-unit01 {

    li {
      color: inherit;
      padding-left: 120px;

      &::before {
        border-right: 1px #0084ca solid;
        color: #0084ca;
        content: "0" counter(ol_li);
        font-family: 'Shippori Mincho', serif;
        font-size: calc(24px + 4 * (100vw - 320px) / 1600);
        font-weight: 700;
        padding-right: 30px;
        line-height: normal;
        position: absolute;
        top: 25px;
        left: 30px;
      }
    }
  }

  .flow-unit02 {
    
    li {
      &::before {
        display: none;
      }
    }
  }
}

.list_1 {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >li {
    width: 48%;
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 1 0 auto;

    >a {
      background: url('../img/common/icon_mark.svg') no-repeat left -10px center #e5f4fb;
      background-color: #e5f4fb;
      border-top: 2px #0084ca solid;
      font-family: 'Shippori Mincho', serif;
      font-size: calc(18px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      color: #222;
      display: flex;
      align-items: center;
      height: 120px;
      text-decoration: none;
      padding: 25px;
      padding-right: 90px;
      margin-bottom: 30px;
      line-height: normal;
      width: 100%;
      max-width: 100%;
      position: relative;

      @include mixin.tabletP {
          height: auto;
      }

      &:hover {
        opacity: .7;
        text-decoration: none;
      }

      &:visited {
        color: inherit;
      }

      &::after {
        background: url('../img/common/icon_arrow_on.svg') no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 32px;
        height: 8px;
        position: absolute;
        top: 50%;
        right: 25px;
      }
    }

    &:nth-child(even) {
      margin-left: 2%;
    }

    >ul {
      flex-direction: column;
      margin-bottom: 50px !important;

      >li {
        display: block;
        border: none;
        padding-left: 0 !important;

        &::before {
            display: none;
        }

        a {
          padding: 0;
          padding-left: 40px;
          font-weight: 500;
          position: relative;
          width: 100%;

          &::before {
            background: url('../img/common/icon_index_arrow.svg') no-repeat center center;
            background-size: cover;
            content: "";
            display: block;
            width: 8px;
            height: 12px;
            position: absolute;
            top: 9px;
            left: 20px;
          }

          &:hover {
            text-decoration: underline;
            opacity: .7;
          }

          &:visited {
            color: inherit;
          }
        }
      }
    }
  }
}

.accordion {
  padding-left: 5px !important;
  position: relative;

  .ac-toggle {
    display: block;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: black;
      position: absolute;
      top: 50%;
      width: 18px;
      height: 1px;
      left: 0;
      transition: all 0.3s;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &::after {
      transform: translateX(-50%);
    }

    &.open {
      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        opacity: 0;
      }
    }
    span {
      display: block;
      padding-left: 20px;
    }
  }

  .content {
    display: none;
  }
}

@include mixin.tabletP {
  .modContents {

    .alignleft,
    .alignright {
      display: block;
      float: none;
      margin: 0 auto 10px;
    }

    .size-full {
      margin: 0 auto 10px;
      width: 100%;
    }

    .scroll_wrap {
      margin-bottom: 30px;
      width: 100%;
      overflow: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;

      &:before {
        content: "→スクロールできます。"
      }
    }

  .list_1 {
      display: block;

      >li {
        width: auto;
        display: block;
        padding-left: 0;

        &:nth-child(even) {
            margin-left: 0;
        }
      }
    }

    .tbl_scroll {
      margin-bottom: 0;
      max-width: 767px !important;
      width: 767px !important;
    }

    .tbl_responsive,
    .tbl_responsive thead,
    .tbl_responsive tfoot,
    .tbl_responsive tbody,
    .tbl_responsive tr,
    .tbl_responsive th,
    .tbl_responsive td {
      display: inline-block;
      width: 100% !important;
    }

    .tbl_responsive02 th,
    .tbl_responsive02 td {
      display: inline-block;
      width: 50% !important;
    }
  }
}