@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/* 上余白 */
.mt8 {
  @include mixin.multiple-m(top, '1');
}

.mt16 {
  @include mixin.multiple-m(top, '2');
}

.mt24 {
  @include mixin.multiple-m(top, '3');
}

.mt32 {
  @include mixin.multiple-m(top, '4');
}

.mt40 {
  @include mixin.multiple-m(top, '5');
}

.mt48 {
  @include mixin.multiple-m(top, '6');
}

.mt64 {
  @include mixin.multiple-m(top, '8');
}

.mt72 {
  @include mixin.multiple-m(top, '9');
}

.mt80 {
  @include mixin.multiple-m(top, '10');
}

.mt88 {
  @include mixin.multiple-m(top, '11');
}

.mt96 {
  @include mixin.multiple-m(top, '12');
}

.mt104 {
  @include mixin.multiple-m(top, '13');
}

.mt120 {
  @include mixin.multiple-m(top, '14');
}

.mt136 {
  @include mixin.multiple-m(top, '17');
}

.mt144 {
  @include mixin.multiple-m(top, '18');
}

.mt160 {
  @include mixin.multiple-m(top, '20');
}

.mt200 {
  @include mixin.multiple-m(top, '25');
}

.mt240 {
  @include mixin.multiple-m(top, '30');
}

/* 下余白 */
.mb8 {
  @include mixin.multiple-m(bottom, '1');
}

.mb16 {
  @include mixin.multiple-m(bottom, '2');
}

.mb24 {
  @include mixin.multiple-m(bottom, '3');
}

.mb32 {
  @include mixin.multiple-m(bottom, '4');
}

.mb40 {
  @include mixin.multiple-m(bottom, '5');
}

.mb48 {
  @include mixin.multiple-m(bottom, '6');
}

.mb64 {
  @include mixin.multiple-m(bottom, '8');
}

.mb72 {
  @include mixin.multiple-m(bottom, '9');
}

.mb80 {
  @include mixin.multiple-m(bottom, '10');
}

.mb88 {
  @include mixin.multiple-m(bottom, '11');
}

.mb96 {
  @include mixin.multiple-m(bottom, '12');
}

.mb104 {
  @include mixin.multiple-m(bottom, '13');
}

.mb120 {
  @include mixin.multiple-m(bottom, '14');
}

.mb136 {
  @include mixin.multiple-m(bottom, '17');
}

.mb144 {
  @include mixin.multiple-m(bottom, '18');
}

.mb160 {
  @include mixin.multiple-m(bottom, '20');
}

.mb200 {
  @include mixin.multiple-m(bottom, '25');
}

.mb240 {
  @include mixin.multiple-m(bottom, '30');
}

/* コンテナー幅 */
.width-ss {
  width: 40%;
}

.width-sm {
  width: 47%;
}

.width-md {
  width: 60%;
}

.width-lg {
  width: 70%;
}

.width-xl {
  width: 93.5%;
}

.width-ss,
.width-sm,
.width-md,
.width-lg,
.width-xl {

  @include mixin.tabletP {
    width: calc(100% - 16px);
  }
}


.inn-container-lg {
  margin: 0 auto;
  padding: 0 16px;
  width: 1100px;
  max-width: 100%;

  @include mixin.tabletP {
    padding: 0 24px;
    width: 100%;
  }
}

.inn-container-sm {
  margin: 0 auto;
  padding: 0 16px;
  width: 820px;
  max-width: 100%;

  @include mixin.tabletP {
    padding: 0 24px;
    width: 100%;
  }
}

.inn-container-md {
  margin: 0 auto;
  width: 960px;
  max-width: 100%;

  @include mixin.tabletP {
    width: calc(100% - 32px);
  }
}

.c-card-wrap {
  background-color: white;
  margin: 0 auto;
  width: 100%;
  max-width: 608px;

  @include mixin.tabletP {
    padding: 54px 0 32px;
    max-width: 536px;
  }

  @include mixin.sp {
    padding: 40px 0 12px;
  }


  .c-card {
    padding: 80px 0 32px;
    margin: 0 auto;
    width: 72%;
    word-break: keep-all;

    @include mixin.tabletP {
      padding: 0 38px;
      width: 100%;
    }
  }
}

.c-flex-container {
  box-sizing: border-box;
  display: flex;

  &__col2 {
    display: flex;
    gap: 40px 56px;
    flex-wrap: wrap;

    &--item {
      width: calc(100% / 2 - 28px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.bg-gray {
  background-color: map_get(variables.$COLOR, pale-gray);
}

.bg-white {
  &.main-container {
    padding: 80px 32px;

    @include mixin.tabletP {
      padding: 64px 32px 88px;
    }

    @include mixin.sp {
      padding: 64px 16px 88px;
    }

    // .c-container-sm {
    //   @include mixin.tabletP {
    //     padding: 0 24px
    //   }
    // }
  }

  .c-olList {
    @include mixin.multiple-m(bottom, '5');
  }
}

.c-container-lg {
  .bg-white {
    /* box-shadow: 0 0 20px 0 rgba(0,0,0,.08); */
    padding: 80px 16px;
    position: relative;

    .bg-white {
      padding-bottom: 80px;
    }

    .inn-container-md {
      padding: 0 24px;
    }
  }
}

.scroll-tbl {
  @include mixin.multiple-m(top, '5');
  @include mixin.multiple-p(bottom, '3');
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: map_get(variables.$COLOR, gray);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
      background: variables.$COLOR_TEXT_GRAY;
      border-radius: 5px;
  }

  &__inner {
    box-sizing: border-box;
    width: calc(820px - 112px);
  }
}

.gallery-unit {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 56px;
  width: 100%;
  max-width: 100%;

  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 48px 56px;

    &.col-type1,
    &.col-type3 {
      width: calc(66.666667% - 28px);

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &.col-type2 {
      width: calc(33.333333% - 28px);

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &.col-type3 {

      .p-gallery-card {
        width: calc(100% / 2 - 28px);

        @include mixin.tabletP {
          width: 100%;
        }
      }
    }

    &.col-type4 {
      width: 100%;

      .p-gallery-card {
        width: calc(100% / 3 - 38px);

        @include mixin.tabletP {
          width: 100%;
        }
      }
    }

    .p-gallery-card {
      width: 100%;
      max-width: 100%;
      .c-ratio {
        @include mixin.multiple-m(bottom, '2');

        img {
          width: 100%;
        }

        &.img-type1 {
          &::before {
            padding-top: 56.25%;
          }
        }

        &.img-type2 {
          &::before {
            padding-top: 66.1992%;
          }
        }

        &.img-type3 {
          &::before {
            padding-top: 143.4650%;
          }
        }
      }

      .card-text {
        color: variables.$COLOR_TEXT_GRAY;
        font-size: 14px;
        line-height: normal !important;
      }
    }
  }
}

.animat-box {
  position: absolute;
}