@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.c-btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  @include mixin.multiple-p(null, '1');
  position: relative;
  transition: all 1s ease-out;

  &::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .3s ease-out;
    width: 100%;
  }

  &.grada-type1 {
    background: rgb(255,140,102);
    background: linear-gradient(45deg, rgb(255, 138, 71) 0%, rgb(255, 145, 49) 100%);

    &::before {
      background: rgb(255,177,128);
      background: linear-gradient(110deg, rgba(255,177,128,1) 0%, rgba(255,133,102,1) 100%);
    }
  }

  &.grada-type2 {
    background: rgb(255,140,102);
    background: linear-gradient(45deg, rgb(255, 138, 71) 0%, rgb(255, 145, 49) 100%);

    &::before{
      background: rgb(255,203,128);
      background: linear-gradient(110deg, rgba(255,203,128,1) 0%, rgba(255,133,102,1) 100%);
    }
  }

  &:hover {
    opacity: 1;

    &::before {
      opacity: 0;
    }
  }

  span {
    color: white;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    position: relative;
  }
}

.c-txt-link {
  color: variables.$COLOR_MAIN;
  display: inline-block;
  font-family: variables.$FONT_ALPHA;
  font-weight: 900;
  text-decoration: none;
  @include mixin.multiple-p(null, '2');
  position: relative;
  transition: .3s ease-in;
  z-index: 1;
  overflow: hidden;

  &::before {
    background: rgb(255,177,128);
    background: linear-gradient(110deg, rgba(255,177,128,1) 0%, rgba(255,133,102,1) 100%);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .2s ease-in;
  }

  &::after {
    background: url(#{mixin.set_common_path('icon_arrow_right_blue.svg')}) no-repeat right center;
    background-size: 100% auto;
    content: "";
    display: block;
    width: 24px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: transform 0s ease;
  }

  span {
    display: inline-block;
    color: variables.$COLOR_MAIN;
    @include mixin.multiple-p('right', '7');
    will-change: transform;
  }

  &:hover {
    opacity: 1;

    &::before {
      transform-origin: 0% 50%;
      transform: scaleX(1);
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_arrow_right_white.svg')}) no-repeat right center;
      background-size: 100% auto;
      animation: fadeRight .2s forwards ease-in;
    }

    span {
      color: white;
      animation: fadeUp .2s forwards ease-in;
    }
  }
}

.btn-modal {
  display: block;
  padding-right: 48px;
  text-decoration: none;
  position: relative;

  &::before {
    background: url(#{mixin.set_common_path('icon_question.svg')}) no-repeat center center;
    background-size: 100% auto;
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
    /* top: 50%;
    transform: translateY(-50%); */

    @include mixin.tabletP {
      top: -4px;
    }
  }
}

@keyframes fadeUp {
  0% {
    opacity: .5;
    transform: translate3d(0, -5px, 0);
  }
  25% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  75% {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeRight {
  0% {
    opacity: .5;
    right: 11px;
  }
  25% {
    opacity: 0;
    right: 11px;
  }
  50% {
    opacity: 0;
    right: 21px;
  }
  75% {
    opacity: .5;
    right: 21px;
  }
  100% {
    opacity: 1;
    right: 16px;
  }
}
