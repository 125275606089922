@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.c-ttl-unit {
  margin-bottom: 60px;

  @include mixin.tabletP {
    margin-bottom: 40px;
  }

  @include mixin.sp {
    margin-bottom: 16px;
  }
  
}

.ttl-en {
  font-family: 'Montserrat', sans-serif;
}

.ttl-main,
.c-card-title {
  color: #231815;
  font-size: calc(32px + 16 * (100vw - 375px) / 1545);
  @include mixin.propertyClamp("font-size", 34, 40, 960, 1366);//ブラウザ幅 960px-34px,1366px-40px
  font-weight: 700;
  line-height: 1;
  @include mixin.sp {
    font-size: 28px;
  }
}

.c-card-text {
  @include mixin.propertyClamp("font-size", 16, 20, 820, 1536);//ブラウザ幅 820px-16px,1536px-20px
  font-weight: 700;
  line-height: 1.8;
  @include mixin.sp {
    font-size: 16px;
  }
}