@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.modal-tbl {
  border-top: 1px map_get(variables.$COLOR, gray) solid;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  @include mixin.multiple-m(bottom, '8');;
  width: 100%;
  max-width: 100%;

  @include mixin.tabletP {
    display: block;
  }

  th,td {
    border-bottom: 1px map_get(variables.$COLOR, gray) solid;
    padding: 32px 0;
    vertical-align: top;

    @include mixin.tabletP {
      display: block;
    }
  }

  th {
    font-weight: 700;
    width: 25%;

    @include mixin.tabletP {
      border-bottom: none;
      @include mixin.multiple-p(bottom, '2');;
      width: 100%;
    }
  }
  td {
    width: 75%;

    @include mixin.tabletP {
      padding-top: 0;
      width: 100%;
    }
  }
}
