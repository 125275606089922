@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* パンくずリスト */
.breadcrumb-unit {
  padding: 16px 0;
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  line-height: 1;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: map_get(variables.$COLOR, gray);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
      background: variables.$COLOR_TEXT_GRAY;
      border-radius: 5px;
  }
}

.breadcrumb {
	&.pankuzu {
		background-color: transparent;
		padding: 0 16px;
		margin-bottom: 0;
		align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    /* overflow-x: auto; */
    -webkit-overflow-scrolling: touch;

    @include mixin.sp {
      width: 800px;
    }
	}
}

.breadcrumb-item {
  display: flex;
  align-items: center;
	padding-right: 16px;

  @include mixin.sp {
    padding-right: 24px;
  }

	a {
    color: variables.$COLOR_TEXT_GRAY;
		font-size: calc(12px + 2 * (100vw - 375px) / 1545);
    font-weight: 500;
		text-decoration: underline;
	}

	+.breadcrumb-item {
		padding-left: 16px;
		position: relative;

    @include mixin.sp {
      padding-left: 20px;
    }

		&::before {
			background: url(#{mixin.set_common_path('icon_arrow_breadcrumb.svg')}) no-repeat center center;
			background-size: auto;
			content: "";
			display: block;
			width: 5px;
			height: 10px;
			line-height: inherit;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-45%);
		}
	}

	&.active {
		color: variables.$COLOR_TEXT;
		font-size: calc(12px + 2 * (100vw - 375px) / 1545);
		padding-right: 0;
    text-decoration: none;
	}
}

/* ページネーション */
.wp-pagenavi {
	border-collapse: separate;
	border-spacing: 15px 0;
	display: table;
	margin: 0 auto 20px;

	@include mixin.tabletP {
		border-spacing: 10px 0;
	}

	a,
	span {
		font-weight: bold;
		height: 40px;
		width: 40px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 0 !important;

		@include mixin.tabletP {
			height: 30px;
			width: 30px;
		}
	}
	a {
		&:hover {
			background-color: #333;
			color: #fff;
			text-decoration: none;
			opacity: .7;
		}

		&.previouspostslink,
		&.nextpostslink{

			&:hover {
				color: #333;
				background-color: transparent;
			}
		}
	}
	span {
		&.current {
			color: white;
			background-color: #333;
		}
	}
}



