@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

/*
c-list
*/

$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';

ul.modListJump {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 60px;
	padding: 0;

	li{
		gap: 10px;
		padding: 10px;

		&:before {
			display: none;
		}

		a {
			text-decoration: none;
			position: relative;
			padding: 0 0 0 30px;

			&:before {
				content: "\f103";
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				position: absolute;
				top: 2px;
				left: 5px;
			}

			&:hover {
				&::before {
					text-decoration: none;
				}
			}
		}
	}
}

.p-news-list {
  display: flex;
  gap: 0 16px;
  text-decoration: none;
  width: 100%;
  max-width: 100%;

  @include mixin.tabletP {
    gap: 4px 0;
    flex-direction: column;
  }

  span {
    color: white;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;

    @include mixin.tabletP {
      font-size: 13px;
    }

    &.date {
      width: 90px;

      @include mixin.tabletP {
        width: 100%;
      }
    }

    &.txt {
      width: calc(100% - 90px);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.p-link-list {

  li {
    border-bottom: 1px white solid;
    cursor: pointer;
    position: relative;

    &::before {
      background-color: rgba(255, 255, 255, .1);
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform .2s ease;
    }

    &:hover {
      &::before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
    }

    &:first-child {
      border-top: 1px white solid;
    }

    .p-link {
      color: white;
      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      text-decoration: none;
      padding: 24px 16px;
      position: relative;
      overflow: hidden;

      @include mixin.tabletP {
        padding: 34px 12px;
      }
      @include mixin.sp {
        padding: 24px 8px;
      }

      &::after {
        background: url(#{mixin.set_common_path('icon_arrow_right_white.svg')}) no-repeat center bottom;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 10px;
        height: 8px;
        position: absolute;
        top: 50%;
        right: 34px;
        transform: translateY(-50%);
      }

      &:hover {
        padding-left: 32px;

        &::after {
          animation: fadeOutIn 2s ease 0s 1 normal forwards;
        }
      }
    }
  }
}

@keyframes fadeOutIn {
  5% {
    opacity: .3;
    right: 29px;
  }
  20% {
    opacity: 0;
    right: 29px;
  }
  21% {
    opacity: 0;
    right: 39px;
  }
  22% {
    opacity: 1;
    right: 39px;
  }
  28% {
    opacity: 1;
    right: 34px;
  }
  100% {
    opacity: 1;
    right: 34px;
  }
}