@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

.em700 {
  font-weight: 700;
}

.ff-alpha {
  font-family: variables.$FONT_ALPHA;
}
.ff-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.fc-gray {
  color: variables.$COLOR_TEXT_GRAY;
}

.fc-main,
.fc-blue {
  color: variables.$COLOR_MAIN;
}

.c-notes-txt {
  color: variables.$COLOR_MAIN;
  font-size: calc(22px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  @include mixin.multiple-m(top, '8');
}