@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;

#footer {
  background-color: variables.$COLOR_MAIN;
  @include mixin.multiple-p('top', '7');
  @include mixin.multiple-p('left', '5');

  @include mixin.tabletP {
    padding-left: 18px;
  }

  @include mixin.sp {
    @include mixin.multiple-p('top', '5');
  }

  // ==[ ページトップへ戻るボタン ]==================================== //
  #scrollToTopBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 9000;
      bottom: 10px;
      right: 10px;
      width: 56px;
      height: 56px;
      background-color: variables.$COLOR_MAIN;
      box-shadow: 0px 0 15px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in-out, opacity 0.6s ease-in-out;

      @include mixin.sp {
        width: 48px;
        height: 48px;
      }

      svg#ico_pagetop{
        width: 35%;
        height: auto;
        fill: none;
        stroke:#fff;
        stroke-linecap: round;
        stroke-width: 3;
        .arrow{
          transition: all 0.15s ease-in-out;
        }
      }
      &:hover {
        background-color: #07255e;
        opacity: 1;

        svg#ico_pagetop {
          .arrow{
            transform: translateY(-10%);
          }
        }

      }
  }

  .sec-inner {
    background-color: white;
    @include mixin.multiple-p('top', '7');
    @include mixin.multiple-p('bottom', '5');
    @include mixin.multiple-p('left', '5');
    @include mixin.multiple-p('right', '5');

    @include mixin.tabletP {
      @include mixin.multiple-p(null, '10');
      @include mixin.multiple-p('left', '2');
      @include mixin.multiple-p('right', '2');
    }

    @include mixin.sp {
      @include mixin.multiple-p(null, '7');
      @include mixin.multiple-p('left', '2');
      @include mixin.multiple-p('right', '2');
    }
  }

  .footer-logo {
    @include mixin.tabletP {
      @include mixin.multiple-m('botto', '8');
    }
  }

  .footer-nav-top {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: auto;
    @include mixin.multiple-p('bottom', '6');

    &__item {

      &.col-left {
        a{
          max-width: 220px;
        }

        @include mixin.multiple-p('right', '4');
        flex: 0 0 27%;
        max-width: 27%;

        @include mixin.tabletP {
          @include mixin.multiple-p('right', '1');
          text-align: center;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &.col-right {
        box-sizing: border-box;
        display: flex;
        flex: 0 0 73%;
        max-width: 73%;

        .col-item {
          display: flex;
          flex-direction: column;
          width: calc(100% / 3);
        }
        .nav-heading {
          color: variables.$COLOR_MAIN;
          display: inline-block;
          font-size: 18px;
          @include mixin.propertyClamp("font-size", 16, 20, 820, 1366);
          font-weight: 700;
          line-height: normal;
          text-decoration: none;
          transition: .3s ease;

          &::before {
            background: url(#{mixin.set_common_path('icon_arrow_right_blue.svg')}) no-repeat right center;
            background-size: 100% auto;
            content: "";
            display: block;
            width: 0;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            transition:width .3s ease;
          }

          &:hover {
            padding-left: 32px;
            position: relative;
            transition: .3s ease;
            opacity: 1;

            &::before {
              width: 18px;
            }
          }

          +.nav-heading {
            @include mixin.multiple-m('top', '6');
          }
        }

        .nav-sub-list {
          @include mixin.multiple-m('top', '1.5');
          word-break: keep-all;

          +.nav-heading {
            @include mixin.multiple-m('top', '6');
          }

          li {
            line-height: 1.4;

            +li {
              margin-top: 8px;
            }

            a {
              display: inline-block;
              text-decoration: none;
              font-size: 14px;
              @include mixin.propertyClamp("font-size", 13, 14, 820, 1366);
              padding-left: 10px;
              position: relative;

              &::before {
                content: "-";
                display: inline-block;
                @include mixin.multiple-p('right', '.85');
                position: absolute;
                left: 0;
              }

              &::after {
                background-color: variables.$COLOR_MAIN;
                content: "";
                display: block;
                height: 1px;
                width: 0;
                position: absolute;
                bottom: -6px;
                left: 0;
                transition: width .3s ease;
              }

              &:hover {
                color: variables.$COLOR_MAIN;
                opacity: 1;

                &::before {
                  text-decoration: none;
                }

                &::after {
                  width: 100%;
                }
              }
            }
          }
        }
      }

    }
  }

  .footar-nav-bottom {
    display: flex;
    align-items: center;
    padding: 0;

    @include mixin.tabletP {
      justify-content: center;
      @include mixin.multiple-m('bottom', '10');
    }

    @include mixin.sp {
      @include mixin.multiple-m('bottom', '0');
      @include mixin.multiple-p('right', '1');
    }

    .navbar-nav {
      gap: 0 32px;
      flex-direction: row;

      .nav-link {
        background: url(#{mixin.set_common_path('icon_blank_black.svg')}) no-repeat right center;
        @include mixin.propertyClamp("font-size", 12, 13, 820, 1366);
        font-weight: 500;
        padding: 0;
        padding-right: 16px;
        text-decoration: none;
        position: relative;

        &::before {
          background-color: variables.$COLOR_MAIN;
          content: "";
          display: block;
          height: 1px;
          width: 0;
          position: absolute;
          bottom: -8px;
          left: 0;
          transition:width .3s ease;
        }

        &:hover {
          background: url(#{mixin.set_common_path('icon_blank_blue.svg')}) no-repeat right center;
          color: variables.$COLOR_MAIN;
          opacity: 1;

          &::before {
            width: 100%;
          }
        }
      }
    }

    .sns-unit {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 0 8px;

      @include mixin.tabletP {
        margin: 0 auto;
      }

      li {

        .sns-link {
          background-color: variables.$COLOR_TEXT;
          display: inline-block;
          border-radius: 100%;
          width: 34px;
          height: 34px;
          overflow: hidden;

          &:hover {
            opacity: 1;
          }

          &.fb {
            &:hover {
              background-color: #3B5998;
            }
          }

          &.tw {
            &:hover {
              background-color: #00ACEE;
            }
          }
        }
      }
    }
  }

  .copyright {
    font-size: 11px;
    font-weight: 500;
    margin-top: 24px;

    @include mixin.tabletP {
      @include mixin.multiple-p('right', '1');
      text-align: center;
    }
  }
}