@use "../foundation/variables" as variables;
@use "../foundation/mixin" as mixin;


/* form
------------------------------------------------------------*/
form {
  width: 100%;
  max-width: 100%;
}

textarea,
input,
input[type="password"],
input[type="email"],
input[type="text"],
input[type="tel"],
select {
  background-color: map_get(variables.$COLOR, middle-gray);
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  color: #858585;
  display: inline-block;
  font-size: 16px; /* スマホ時拡大防止のためフォントサイズ固定 */
  padding: 8px 16px;
  margin: 0;
  width: 100%;
  max-width: 100%;

  &::placeholder,
  &::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    color: #858585;
  }

  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    color: #858585;
  }

  &.error {
    background-color: rgba(255, 242, 242, 1.00);
    border: solid 2px map_get(variables.$COLOR, red)
;

  }
  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid rgba(0, 0, 0, 1);
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 2px;

    @include mixin.tabletP {
      outline: 0px solid rgba(0, 0, 0, 0) !important;
    }
  }
}

label {
  vertical-align: middle;
}

select {
  &.custom-select {
    background: #fff url(#{mixin.set_common_path('icon_select_btn.svg')}) right center/42px 42px no-repeat;
    background-color: map_get(variables.$COLOR, middle-gray);
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    color: #858585;
    display: inline-block;
    font-size: 14px; /* スマホ時拡大防止のためフォントサイズ固定 */
    padding: 8px 16px;
    padding-right: 42px;
    margin: 0;
    width: 100%;
    max-width: 100%;

    @include mixin.tabletP {
      background: #fff url(#{mixin.set_common_path('icon_select_btn.svg')}) right center/54px 54px no-repeat;
      background-color: map_get(variables.$COLOR, middle-gray);
      font-size: 16px; /* スマホ時拡大防止のためフォントサイズ固定 */
    }
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    color: #858585;
  }

  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: normal;
    color: #858585;
  }
}

/* radio
-------------*/
input[type="radio"] {
  /* @include accessibilityHidden; */

  &+.label {
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 3px 24px;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      border-radius: 100%;
      content: '';
      position: absolute;
      top: 50%;
      transition: all .2s;
    }

    &::before {
      background: #EEE;
      border: 1px solid #ccc;
      height: 16px;
      left: 0;
      margin-top: -9px;
      width: 16px;
    }

    &::after {
      background: map_get(variables.$COLOR, blue);
      height: 10px;
      left: 3px;
      margin-top: -6px;
      opacity: 0;
      transform: scale(0.5);
      width: 10px;
    }

    &:hover {
      &::before {
        background: #FEFEFE;
      }
    }
  }

  &.error {
    &+.label {
      &::before {
        background-color: rgb(255, 242, 242);
        border: 1px solid map_get(variables.$COLOR, red);
      }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background: #fff;
        border: 1px solid map_get(variables.$COLOR, blue);
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

/* checkbox
-------------*/
input[type="checkbox"] {
 /*  @include accessibilityHidden; */

  &+.label {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    padding-left: 30px;
    position: relative;
    transition: all .2s;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      background: white;
      border: 1px solid map_get(variables.$COLOR, gray);
      border-radius: 0;
      height: 22px;
      width: 22px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &::after {
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      height: 8px;
      width: 12px;
      opacity: 0;
      top: 50%;
      left: 2%;
      transform: translateY(-80%) rotate(-45deg);
    }

    &:hover {
        &::before {
          background: #fff;
        }
    }
  }

  &:checked {
    &+.label {
      &::before {
        background: variables.$COLOR_MAIN;
        border-color: variables.$COLOR_MAIN;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

/* ==[ contact form7 ]==================================== */
/* ラジオボタン・チェックボックス共通 */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: auto;
}

/* チェックボックス */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {

    &.wpcf7-acceptance,
    &.wpcf7-checkbox {
      display: flex;
      flex-wrap: wrap;

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
      }

      span.wpcf7-list-item {
        position: relative;
        margin: 0 50px 0 0 !important;
      }

      .wpcf7-list-item-label {
        color: #4D4D4D;
        cursor: pointer;
        font-size: calc(14px + 2 * (100vw - 375px) / 1545);
      }

      .wpcf7-list-item-label:before {
        content: '';
        border: 1px solid #8b8b8b;
        border-radius: 2px;
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: -3px;
        margin-right: 10px;
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
      }

      input[type="checkbox"]:checked+.wpcf7-list-item-label {
        &::before {
          border-color: #8b8b8b;
          background-color: white;
        }

        &::after {
          background: url("../img/common/icon_check.svg") white no-repeat center;
          background-size: contain;
          content: "";
          display: block;
          width: 16px;
          height: 12px;
          position: absolute;
          top: 6px;
          left: 3px;
        }
      }
    }
  }
}

/* ラジオボタン */
.wpcf7-form-control-wrap {
  display: block;

  .wpcf7-form-control {
    &.wpcf7-radio {
      display: flex;

      span.wpcf7-list-item {
        position: relative;
        flex-basis: 50%;
        width: 100%;

        &.first {
          margin: 0 !important
        }
      }

      .wpcf7-list-item-label {
        color: #333;
        cursor: pointer;
        font-size: 13px
      }

      input[type="radio"] {
        position: absolute;
        opacity: 0;
      }

      .wpcf7-list-item-label:before {
        content: '';
        background: #fff;
        border-radius: 100%;
        border: 1px solid #888888;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 14px;
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
        -webkit-transition: all 250ms ease;
        transition: all 250ms ease;
      }

      input[type="radio"]:checked+.wpcf7-list-item-label:before {
        background-color: #212121;
        box-shadow: inset 0 0 0 2px #fff;
      }
    }
  }
}

/* ajax loader */
.wpcf7 {
  .ajax-loader {
    display: block !important;
    text-align: center;
    margin: 0 auto !important;
  }
}


/* ==[ MW WP Form ]==================================== */
/* ラジオボタン */
.mwform-radio-field {
  margin: 0 .5em;

  input[type=radio] {
    appearance: none;
    display: none;

    + span {
      display: inline-block;
      cursor: pointer;
      padding-left: 20px;
      position: relative;

      &:before {
        border: 1px #333 solid;
        border-radius: 100%;
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    &:checked {
      + span {
        &:before {
          background-color: #333;
          border-radius: 100%;
          box-shadow: inset 0 0 0 2px #fff;
        }
      }
    }
  }
}

/* チェックボタン */
.mwform-radio-field {
  margin: 0 .5em;

  input[type=checkbox] {
    appearance: none;
    display: none;

    + span {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;

      &:before {
        border: 1px #333 solid;
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }

    &:checked {
      + span {
        &::before {
          background: url("../img/common/icon_check.svg") white no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

/* 必須badge */
.required {
  background-color: #a41f27;
  border-radius: 0;
  color: white;
  display: inline-block;
  font-size: calc(10px + 2 * (100vw - 320px) / 1600);
  line-height: normal;
  padding: 0 5px 2px;
  margin-right: 10px;
}
