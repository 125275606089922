@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

#header {
  transition: .3s ease;
  position: relative;
  z-index: 7000;

  &.nav-open {
    position: relative;

    @media screen and (max-width: 959px ) {
      .navbar {
        background-color: white;
        transition: .3s;
        will-change: transform, opacity;
      }

      .nav-link {
        color: variables.$COLOR_TEXT;
        transition: all .7s .2s ease;
      }
    }
  }

  .navbar-brand {
    display: inline-block;
    width: 22%;
    max-width: 280px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 1099px) {
      display: none;
    }

    @media screen and (max-width: 959px ) {
      display: inline-block;
      margin-left: 10px;
      width: 62%;
      max-width: 280px;
      position: static;
      left: auto;
      top: auto;
      transform: translateY(0);
    }
  }

  .collapse {
    padding: 0 16px;
    width: 95.83%;

    @media screen and (max-width: 1099px) {
      width: 100%;
    }

    @media screen and (max-width: 959px) {
      background-color: white;
      padding: 0;
    }
  }

  .collapse-inner {
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
    max-width: 800px;


    @media screen and (max-width: 1099px) {
      width: 95.83%;
      max-width: initial;
    }

    @media screen and (max-width: 959px) {
      background-color: map_get(variables.$COLOR, middle-gray);
      height: 100vh;
      overflow-y: auto;
      padding: 0;
      padding-bottom: 150px;
      width: 100%;
      max-width: 100%;
    }
  }

  .nav-item {
    text-align: right;

    @media screen and (max-width: 959px ) {
      border-bottom: 1px #dcdcdc solid;
      display: block;
      text-align: left;
      width: 100%;
    }

    &.nav-item-sp {
      display: none;

      @media screen and (max-width: 959px ) {
        display: block;
        border-bottom: none;

        &:last-child {
          border-bottom: 1px #dcdcdc solid;
        }

        .nav-link {
          font-size: 13px;
          font-weight: 500;
          display: block;
        }
      }
    }
  }

  .nav-link {
    color: white;
    font-weight: 700;
    padding: 0;
    text-decoration: none;
    white-space: nowrap;

    @media screen and (max-width: 959px ) {
      display: block;
      text-align: left;
      white-space: normal;
    }
  }

  .contact-unit {
    /* padding-left: 32px; */
    text-align: left;
    width: 20.17%;
    max-width: 210px;

    @media screen and (max-width: 959px ) {
      display: none;
    }

    span {
      display: inline-block;
      padding-left: 22px;
      padding-right: 4px;
      position: relative;
      font-size: 14px;

      &::before {
        background: url(#{mixin.set_common_path('icon_entry_white.svg')}) no-repeat center center;
        background-size: 100% auto;
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .grada-type2 {
      display: block;
      padding: 12px 10px;
      margin-left: auto;

      &:hover {
        box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .contact-unit-sp {
      display: none;
  
      @media screen and (max-width: 959px) {
        display: block;
        padding: 20px 40px;
      }
  
      span {
        @media screen and (max-width: 959px) {
          display: inline-block;
          padding-left: 28px;
          position: relative;
    
          &::before {
            background: url(#{mixin.set_common_path('icon_entry_white.svg')
          }) no-repeat center center;
          background-size: 100% auto;
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  
    .grada-type2 {
      @media screen and (max-width: 959px) {
        background: rgb(255, 162, 0);
        background: linear-gradient(45deg, rgba(255, 162, 0, 1) 0%, rgba(255, 162, 0, 1) 100%);
        display: block;
        padding: 15px 10px;
        margin-left: auto;
    
        &:hover {
          box-shadow: 0px 6px 20px 1px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .navbar-nav {
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    width: 100%;
    font-size: 14px;
    @include mixin.propertyClamp("font-size", 14, 15, 960, 1366);

    @media screen and (max-width: 959px ) {
      justify-content: flex-start;
      gap: 0;
    }

    &.top-navbar {
      margin-top: 8px;
      @include mixin.multiple-m('bottom', '.85');
      transition: .3s;

      @media screen and (max-width: 1099px) {
        @include mixin.multiple-m('top', '.5');
        @include mixin.multiple-m('bottom', '0');
      }

      @media screen and (max-width: 959px ) {
        display: none !important;
      }

      .sp-item {
        display: none;

        @media screen and (max-width: 1099px) {
          display: inline-block;
          margin-right: auto;
          min-width: 220px;
          width: 22%;
        }

        @media screen and (max-width: 959px) {
          display: none;
        }
      }

      .nav-link {
        font-size: 12px;
        font-weight: 500;
        position: relative;
        padding: 0 20px;

        &.link-blank {
          display: inline-block;
          padding-right: 14px;
          margin-right: 6px;

          &::after {
            background: url(#{mixin.set_common_path('icon_blank_white.svg')}) no-repeat center;
            background-size: 100% auto;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: all .3s ease-in;
          }
        }
      }
    }

    &.bottom-navbar {
      .nav-link {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 40px;
        position: relative;
        padding: 16px;
        padding: 0 clamp(16px, 3.01vw + -17.08px, 24px);

        @media screen and (max-width: 959px ) {
          display: block;
          padding: 24px 16px;
          margin: 0 auto;
          height: auto;
          width: 82.68%;
        }

        &::before {

          @media screen and (max-width: 959px ) {
            display: none;
          }

          background-color: #1847c7;
          content: "";
          display: block;
          height: 5px;
          width: 0;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: width .3s ease;
        }

        &:hover {
          opacity: 1;

          &::before {
            @media screen and (max-width: 959px ) {
              display: none;
            }

            width: 38px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .sp-container-top {
    width: 22%;
    min-width: 280px;

    @media screen and (max-width: 1099px) {
      min-width: 248px;
    }

    @media screen and (max-width: 959px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      width: 100%;
      max-width: 100%;
    }
  }

  .navbar {
    padding: 0;
    will-change: transform, opacity;
    backface-visibility:hidden;
    transition: all .7s .2s ease;

    @media screen and (max-width: 1099px) {
      display: block;
      /* padding-top: 20px; */
    }

    @media screen and (max-width: 959px) {
      padding-top: 0;
    }

    &:hover {
      background-color: white;
      transition: all .3s ease-in;

      .nav-link {
        color: variables.$COLOR_TEXT;
        transition: all .4s .1s ease-in;

        &.link-blank {
          &::after {
            background: url(#{mixin.set_common_path('icon_blank_black.svg')}) no-repeat center;
            background-size: 100% auto;
            transition: all .4s .1s ease-in;
          }
        }
      }
    }

    &.navbar-expand-custom {

      @media (min-width: 1100px) {
        .navbar-collapse {
          justify-content: flex-end;
        }
      }

      @media (min-width: 960px) {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
          flex-direction: row;

          &[aria-hidden="false"] {
            display: flex !important;
            transition: .5s;
            &.top-navbar{
              margin-top: 12px;
            }
          }

          .nav-link{
            padding: 0 clamp(16px,3.01vw - 17.08px,24px);
          }
        }

        .navbar-toggler {
          display: none;
        }

        .navbar-collapse {
          display: flex !important;
          align-items: center;

        }
      }

      .bottom-navbar {
        .nav-link:not(.dropdown-toggle) {
          position: relative;

          &::after {
            border: none;
            display: none;

            @media screen and (max-width: 959px) {
              background: url(#{mixin.set_common_path('icon_arrow_right_blue.svg')}) no-repeat left bottom;
              background-size: 100% auto;
              content: "";
              display: block;
              width: 16px;
              height: 13px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }

        }

        .dropdown-toggle {

          &[data-toggle="dropdown"] {

            &::after {
              display: none;
            }

            @media screen and (max-width: 959px) {
              &::before,
              &::after {
                background-color: variables.$COLOR_MAIN;
                border: none;
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: auto;
                right: 0;
                width: 16px;
                height: 2px;
                transform: translateY(-50%);
                transition: .3s;
              }

              &::before {
                transform: translate(0, 0) rotate(0deg);
              }
            }
          }

          &[aria-expanded="false"] {
            &::after {
              transform: rotate(90deg);
              transition: .3s;
            }
          }

          &[aria-expanded="true"] {
            &::after {
              transform: rotate(0deg);
              transition: .3s;
            }
          }
        }

        .nav-item-sp {

          @media screen and (max-width: 959px ) {

            .nav-link {
              &.link-blank {
                &::after {
                  background: url(#{mixin.set_common_path('icon_blank_blue.svg')}) no-repeat center;
                  background-size: 100% auto;
                  width: 10px;
                  height: 10px;
                }
              }

              &::after {
                width: 10px;
                height: 8px;
              }
            }
          }
        }
      }
    }

    &.js-hover {
      background-color: white;
      transition: .1s;
      will-change: transform, opacity;

      .nav-link {
        color: variables.$COLOR_TEXT;

        &.link-blank {
          &::after {
            background: url(#{mixin.set_common_path('icon_blank_black.svg')}) no-repeat center;
            background-size: 100% auto;
          }
        }
      }
    }

    &.js-header {
      background-color: white;
      transition: .5s;
      will-change: transform, opacity;

      @media screen and (max-width: 1099px) {
        padding: 0;
        height: auto;
      }

      .nav-link {
        color: variables.$COLOR_TEXT;
      }

      .top-navbar {

        .nav-link {

          &.link-blank {
            &:hover {
              &::after {
                background: url(#{mixin.set_common_path('icon_blank_black.svg')}) no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }
      }

      .bottom-navbar {
        .nav-link {
          height: 82px;
          justify-content: center;

          @media screen and (max-width: 1099px) {
            height: 52px;
            justify-content: flex-start;
          }

          @media screen and (max-width: 959px) {
            line-height: normal;
            padding-bottom: 10px;
            height: auto;
          }
        }
      }
    }

    &.page-navbar {
      background-color: map_get(variables.$COLOR, pale-gray);
      border-bottom: 1px map_get(variables.$COLOR, middle-gray) solid;
      width: 100%;
      will-change: transform, opacity;
      backface-visibility:hidden;
      transition: .5s;
      z-index: 8000;

      &:hover {
        background-color: white;
      }

      .nav-link {
        color: variables.$COLOR_TEXT;
      }

      .top-navbar {
        .nav-link {

          &.link-blank {
            &::after {
              background: url(#{mixin.set_common_path('icon_blank_black.svg')}) no-repeat center;
              background-size: 100% auto;
            }
          }
        }
      }

      &.js-header {
        background-color: white;
        height: 82px;
        transition: .5s;

        @media screen and (max-width: 1099px) {
          padding: 0;
          height: auto;
        }

        .bottom-navbar {
          .nav-link {
            height: 82px;
            justify-content: center;

            @media screen and (max-width: 1099px) {
              height: 52px;
              justify-content: flex-start
            }

            @media screen and (max-width: 959px) {
              line-height: normal;
              padding-bottom: 24px;
              height: auto;
            }
          }
        }
      }
    }

    &.fixed-top {
      z-index: 8000;

      @media screen and (max-width: 1099px ) {
        display: block;
      }
    }

    .nav-link {
      @media screen and (max-width: 959px) {
        color: variables.$COLOR_TEXT;
      }
    }
  }

  /* ナビトグル */
  .navbar-toggler {
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    padding: 5px;
    width: 63px;
    height: 63px;
    position: relative;
    top: auto;
    right: 10px;
    z-index: 9000;

    @media screen and (max-width: 959px) {
      background: rgb(255,203,128);
      background: linear-gradient(110deg, rgba(255,203,128,1) 0%, rgba(255,133,102,1) 100%);
    }

    &:hover {
      opacity: 1;
    }
  }

  .navbar-toggler-icon {
    background-image: none;
    background-color: white;
    width: 33px;
    height: 2px;
    display: block;
    position: absolute;
    transition: ease .5s;
    left: 50%;
    transform: translate(-50%, 0);

    &:nth-of-type(1) {
        top: 25px;
    }

    &:nth-of-type(2) {
        bottom: 25px;
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon {

      &:nth-of-type(1) {
        top: 30px;
        transform: translateX(-50%) rotate(45deg);
      }

      &:nth-of-type(2) {
        bottom: 30px;
        transform: translateX(-50%) rotate(135deg);
      }
    }
  }

  /* ドロップダウンメニュー */
  .mega-dropdown {
    position: static;
    transition: .5s;

    &.show {
      @media screen and (max-width: 959px ) {
        background-color: white;
        transition: .3s;
      }

      .dropdown-toggle {
        transition: .3s;

        @media screen and (max-width: 959px ) {
          color: variables.$COLOR_MAIN;
          transition: .3s;
        }
      }
    }

    .mega-dropdown-menu {
      background-color: map_get(variables.$COLOR, pale-gray);
      border: none;
      border-top: 1px map_get(variables.$COLOR, middle-gray) solid;
      border-radius: 0;
      margin: 0;
      padding: 40px 0;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      display: block;//reset
      transform: scaleY(0);
      transform-origin: center top;
      transition: all .3s .2s ease-in , opacity .3s .3s;
      z-index: 5000;
      box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.08);

      *{
        opacity: 0;
        transition: opacity .4s .2s;

        @media screen and (max-width: 959px ) {
          opacity: 1;
        }
      }

      @media screen and (max-width: 959px ) {
        position: static;
        display: none;
        transform: scaleY(100%);
        float: none;
        padding: 0;
        opacity: 1;
        transition: all .4s, display 0.4s;
      }

      >.c-container-lg{
        width: calc(100% - 40px);
      }

      &.show {
        transition: .5s,;

        @media screen and (max-width: 959px ) {
          background-color: white;
          display: block;
          transition: all .4s, display 0.4s;
        }
      }
    }

    .col-left {
      width: 23%;
      min-width: max-content;

      @media screen and (max-width: 959px ) {
        display: none;
      }

      .ttl-en {
        @include mixin.propertyClamp("font-size", 13, 15, 820, 1366);
        font-weight: 700;
        font-feature-settings: "palt";
        line-height: 1.2;
      }

      .ttl-main-ja {
        @include mixin.propertyClamp("font-size", 24, 32, 820, 1366);
        font-weight: 600;
        font-feature-settings: "palt";
        line-height: 1.2;
      }
    }

    .col-right {
      width: 75%;

      @media screen and (max-width: 959px ) {
        width: 100%;
      }
    }
  }

  .dropdown-link-unit {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 959px ) {
      display: block;
      margin: 0 auto;
      width: calc(82.68% + 32px);
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-decoration: none;
      line-height: normal;
      padding: 18px 12px;
      width: calc(100% / 3);
      font-size: 14px;
      line-height: 1.25;
      white-space: normal;
      box-shadow: 0 0 0 0 rgba(45, 45, 45, 0);
      transition: all .25s ease !important;

      @media screen and (max-width: 959px ) {
        border-top: 1px #dcdcdc solid;
        display: block;
        font-size: calc(14px + 2 * (100vw - 375px) / 584);
        padding: 24px 16px;
        height: auto;
        width: 100%;
        max-width: 100%;
      }

      &:first-child {
        @media screen and (max-width: 959px ) {
          border-top: none;
        }
      }

      span {
        display: block;
        padding-left: 20px;
        width: 100%;
        position: relative;
        word-break: keep-all;

        @media screen and (max-width: 959px ) {
          padding-left: 0;
        }

        &::before {
          background: url(#{mixin.set_common_path('icon_arrow_right_blue.svg')}) no-repeat center bottom;
          background-size: 100% auto;
          content: "";
          display: block;
          width: 10px;
          height: 8px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          @media screen and (max-width: 959px ) {
            display: none;
          }
        }
      }

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 1;
        box-shadow: 0 0 24px 0 rgba(45, 45, 45, 0.05);
        padding: 16px 6px 16px 18px;

        span {
          color: variables.$COLOR_MAIN;
        }
      }

      &.sp-item {
        display: none;

        @media screen and (max-width: 959px ) {
          display: block;
        }
      }
    }
  }

  /* ドロップダウンホバー処理 */
  .dropdown {
    @media screen and (min-width: 960px) {
      &:hover {
        >.mega-dropdown-menu {
          transform: scaleY(1);
          opacity: 1;
          transition: all .3s ease , opacity .3s .15s;
          *{
            opacity: 1;
            transition: opacity .5s ease, background-color .5s ease;
          }
        }
      }
    }
  }
}